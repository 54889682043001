@use "../variables";
@use "../services/other_services.scss";
@use "content_page-mixins";

.content-page--slider .container .scroll-category {
    display: none;
}



#staticContentContainer {
    @include content_page-mixins.purchase-info;
}

@media(min-width: variables.$responsive-break) {
    .content-page {
        &--slider {
            display: flex;

            &:before {
                background-color: #F1F2F6;
                content: "";
                flex-grow: .5;
            }

            .container {
                margin: 0;
                width: 100%;
            }
        }
    }
}

@media(max-width: variables.$responsive-break - 1) {

    .content-page--slider {

        .container {
            .row {
                flex-direction: column;

                .sidebar-menu {
                    overflow: auto;

                    .content-sidebar {
                        background-color: initial;
                        padding: 0;
                        display: block;
                        margin: 0;

                        &__list {
                            display: flex;
                            justify-content: space-between;
                            margin: 0;
                            padding: 0;
                            height: 50px;

                            .list-item:first-child {
                                margin-left: auto;
                                border: 0;
                                padding-left: 16px;
                            }

                            .list-item:last-child {
                                margin-right: auto;
                                padding-right: 16px;
                            }

                            .list-item {
                                display: block;
                                border-left: 1px solid variables.$neutral-lighter-50;
                                margin: 0;
                                white-space: nowrap;

                                a .link {
                                    height: 100%;
                                    font-size: variables.$font-size-sm;
                                    font-weight: variables.$font-weight-normal;
                                    color: variables.$neutral-darker;
                                    text-decoration: none;

                                    &-title {
                                        padding: 16px 24px 0 24px;
                                    }
                                }

                                &.link-title--active {
                                    background-color: variables.$primary-lighter-10;
                                }


                                &:hover {
                                    border-radius: 4px;
                                    background: variables.$neutral-lighter-25;
                                }
                            }
                        }
                    }
                }

                .scroll-category.d-flex {
                    position: absolute;
                    width: 100%;
                    display: flex;

                    .scroll-left, .scroll-right {
                        position: absolute;
                        height: 50px;
                        width: 32px;
                        padding: 0;
                        background-color: variables.$white;
                        color: variables.$secondary;
                        border: 0;
                    }

                    .scroll-left {
                        left: 0;
                        border-radius: 0 12px 12px 0;
                        filter: drop-shadow(4px 0px 4px #CCC);
                    }

                    .scroll-right {
                        right: 0;
                        border-radius: 12px 0 0 12px;
                        filter: drop-shadow(-4px 0px 4px #CCC);
                    }
                }
                #staticContentContainer {
                    @include content_page-mixins.purchase-info_responsive;
                }

            }
        }
    }
}