@use "site";

$site: site.$site;
$euronics-ee: "EuronicsEE";
$nova: "NovastarLT";
$avi: "AvitelaLT";
$site-nova-or-avi: $site == $nova or $site == $avi;
$site-avi: $site == $avi;
$site-nova: $site == $nova;
$site-euronics-ee: $site == $euronics-ee;

//Tint stack - do not use these values directly or it will mess up LT theming. Use color variables
$yellow-10: #FEFAED;
$yellow-25: #FFFAE7;
$yellow-50: #FBEDB3;
$yellow-500: #F1C400;
$yellow-600: #D6AB00;
$yellow-800: #E4B600;

$blue-5: #F0F8FF;
$blue-10: #EBF3FF;
$blue-50: #E0EBFF;
$blue-100: #E4F3FA;
$blue-101: #BED8F6;
$blue-102: #CCD6F4;
$blue-150: #ABD8ED;
$blue-200: #BAC3D6;
$blue-300: #0798E3;
$blue-400: #0E66CB;
$blue-500: #1D428A;
$blue-600: #00178F;
$blue-800: #001066;
$blue-801: #000D52;
$blue-900: #0B265B;
$blue-901: #0E2940;
$blue-902: #091B2A;
$blue-903: #00093A;
$blue-904: #10314C;
$blue-905: #040D15;

$gray-10: #FBFBFB;
$gray-15: #F8F8F5;
$gray-20: #F8F8F8;
$gray-25: #F1F3F6;
$gray-50: #E4E6EC;
$gray-100: #D6D8E1;
$gray-200: #C0C3CE;
$gray-250: #AAAAAA;
$gray-300: #9096A4;
$gray-400: #646B7D;
$gray-500: #262E44;
$gray-600: #2D2C32;
$gray-700: #8E8E8E;
$gray-500-opaque: #262E4480;

$black-500: #000000;
$white-500: #FFFFFF;

$orange-400: #FF8700;
$orange-500: #E85318;
$orange-700: #E63422;
$orange-800: #CD363E;

$pink-25: #FCE9E6;
$pink-500: #E2499B;
$green-700: #02C34F;

// Start Novastar OG Colors
$color-purple: #55499E;
$color-gigas: #5846A4;
$color-port-gore: #221D51;
$color-port-gore-dark: #201B4D;
$color-port-gore-dark-10: #171541;
$color-port-gore-light: #28235E;
$color-blue-viole: #6957B9;
$color-blue-viole-10: #7D36C3;
$color-blue-viole-20: #7230B3;
$color-lavender: #CA7EE3;
$color-blue-viole-light: #C6BDFA;
$color-purple-100: #BEB9DF;

$color-cyan: #40FAFF;
$color-lima: #69C120;
$color-lima-background: #69C1201A;
$color-carnation: #FC4E63;

$color-discount-light: #F24C60;
$color-discount-dark: #D74052;

$color-bright-sun: #FFCD41;
$color-mercury: #E7E7E7;

$color-gray: $color-mercury;
$color-blueish-gray: #E8E8ED;
$color-light-gray: #F9F9F9;
$color-lighter-gray: #F7F7F7;
$color-semi-gray: #D4D4DA;
$color-dark-gray: #4C4C4C;
$color-gray-39: #363a3c;
$color-muted: #5A5A5A;
$color-black: #000000;

$purple-gray-10: #EFEDF4;
$purple-gray-25: #EFEDF5;
$purple-gray-50: #E3DFEC;
$purple-gray-50-2: #E2DEED;

$color-divider: #F0F0F0;

$brand-primary: $color-gigas;
$brand-secondary: $white-500;
$brand-success: $color-lima;
$brand-danger: $color-carnation;
$brand-warning: $color-bright-sun;
// End Novastar OG Colors

// Colors - keeping color names out of variable names.
// Each variant can have 2 light/dark variants of increasing value (dark/darker - light/lighter)
// If additional tints are needed, add them to the last variant along with value e.g. primary-lighter-10 (even lighter)

// Primary
$primary: $blue-600;
$type: $black-500;
$primary-dark: $blue-800;
$primary-dark-10: $blue-801;
$primary-darker: $blue-900;
$primary-darker-10: $blue-903;
$primary-light: $blue-300;
$primary-light-10: $blue-400;
$primary-lighter-10: $blue-10;
$tooltip-background: $blue-5;
$tooltip-background-hover: $gray-25;
$comparison-border: $blue-101;
$content-page-border: $blue-102;
@if $site == $nova {
    $primary: $color-gigas;
    $primary-dark: $color-port-gore;
    $primary-dark-10: $color-port-gore-dark-10;
    $primary-darker: $color-port-gore-dark;
    $primary-light: $color-blue-viole;
    $primary-light-10: $color-blue-viole-10;
    $primary-lighter: $color-blue-viole-light;
    $comparison-border: $color-purple-100;
}

@if $site == $avi {
    $primary: $blue-901;
    $primary-dark: $blue-902;
    $primary-dark-10: $blue-904;
    $primary-darker-10: $blue-905;
    $type: $blue-901;
    $primary-lighter-10: $gray-25;
    $tooltip-background: $gray-25;
    $tooltip-background-hover: $gray-50;
    $primary-lighter: $gray-200;
    $comparison-border: $gray-200;
}

$primary-lighter: $blue-200;
$primary-lighter-50: $blue-50;

// Secondary
$secondary: $yellow-500;
@if $site == $nova {
    $secondary: $color-bright-sun;
}

$secondary-dark: $yellow-600;
$secondary-darker: $yellow-800;
$secondary-light: $yellow-50;
$secondary-lighter: $yellow-25;

// Neutral
$neutral-lighter-10: $gray-10;
$neutral-lighter-15: $gray-15;
$neutral-lighter-20: $gray-20;
$neutral-lighter-21: $color-lighter-gray;
$neutral-lighter-25: $gray-25;
$neutral-lighter-50: $gray-50;
$neutral-lighter-100: $gray-100;
$neutral-lighter: $gray-200;
$neutral-light: $gray-250;

$neutral: $gray-300;
$neutral-dark: $gray-400;
$neutral-darker: $gray-500;
$neutral-darker-600: $gray-600;
$neutral-darker-700: $gray-700;
$neutral-darker-opaque: $gray-500-opaque;

@if $site == $nova {
    $neutral-lighter-25: $purple-gray-25;
    $neutral-lighter-50: $purple-gray-50-2;
}


// Support colors
$black: $black-500;
$white: $white-500;

$supporting-01:  $orange-500;
$supporting-01-dark:  $orange-700;
@if $site == $nova {
    $supporting-01: $color-discount-light;
    $supporting-01-dark: $color-discount-dark;
}

$supporting-02: $pink-500;
$supporting-02-light: $pink-25;

$supporting-03: $green-700;
$supporting-04:  $orange-400;
$supporting-05: $blue-600;
$supporting-06: $blue-100;
$supporting-07: $blue-150;
$supporting-08: $neutral-lighter-15;
$supporting-09: $yellow-500;
@if $site == $nova {
    $supporting-03: $color-lima;
    $supporting-04: $color-discount-light;
    $supporting-05: $color-purple;
    $supporting-06: $purple-gray-10;
    $supporting-07: $purple-gray-50;
    $supporting-08: $yellow-10;
}
@else if $site == $avi {
    $supporting-02: $orange-800;
    $supporting-04: $color-discount-light;
    $supporting-05: $primary;
    $supporting-06: $neutral-lighter-25;
    $supporting-07: $neutral-lighter-100;
}

$ltblue-gradient: linear-gradient(193.54deg, $primary-light -.78%, #00A0DE 80.26%);


//z-index
$zindex-customize-cookie: 999998;
$zindex-panel: 199999;
$zindex-panel-overlay: 1100;
$zindex-tooltip: 1090;

$zindex-loader: 1070;
$zindex-modal: 1060;
$zindex-autocomplete: 200000;
$zindex-alert: 1035;
$zindex-header: 1030;
$zindex-header-slide-menu: 1041;
$zindex-tabs: 1010;
$zindex-header-overlay: 1000;
$zindex-product-overlays: 990;
$zindex-sticky: 980;
$zindex-maps-overlay: 100;
$zindex-comparison-footer: 1091;
$zindex-positive: 1; // Default z-index when there is no contest
$zindex-product-card-overlays: $zindex-positive;
$zindex-nil: 0;
$zindex-pseudo-bg: -1;

//Font
$font-size-default: 16px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-sm: 15px;
$font-size-2sm: 18px;
$font-size-md: 19px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-2xl: 28px;
$font-size-3xl: 32px;
$font-size-4xl: 36px;
$font-size-5xl: 40px;
$font-size-6xl: 44px;
$font-size-7xl: 48px;
@if $site-nova-or-avi {
    $font-size-default: 15px;
    $font-size-xs: 11px;
    $font-size-s: 13px;
    $font-size-sm: 14px;
    $font-size-md: 18px;
    $font-size-lg: 19px;
    $font-size-xl: 23px;
    $font-size-2xl: 27px;
    $font-size-3xl: 31px;
    $font-size-4xl: 35px;
    $font-size-5xl: 39px;
    $font-size-6xl: 43px;
    $font-size-7xl: 47px;
}

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

//Shadows
$shadow-autocomplete-result: 1px 1px 2px 0 rgba(0, 0, 0, .478);
$default-box-shadow: 0px 4px 10px rgba(15, 32, 78, 0.17); // LT

$top-shadow: 0 -2px 4px rgba(0, 0, 0, .40);

// Heights
$description-block: 535px;


// Grid breakpoints
$grid__bp-sm: 786;
$grid__bp-md: 1280;
$grid__bp-lg: 1216;
$grid__bp-xl: 1440;
$grid__bp-xxl: 1920;
$grid__cols: 12;

$large-responsive-break: 1921px;
$responsive-break: 1216px;
$mobile-break: 750px;
$small-mobile-break: 500px;

// Typography
$font-family: Nexa, sans-serif;
@if $site-nova-or-avi {
    $font-family: Leedu-font, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

$paragraph-lh: 1.5;
$lh-small: 1.063;
$lh-medium: 1.5;
$lh-large: 1.7;

$border-radius-round: 4px;
$border-radius-rounder: 8px;
$border-radius-circle: 25px;

// Alerts
$alert-success: #EBFFF3;
$alert-danger: $supporting-02-light;
$alert-box-radius: 3px;

// Banner in services
$banner-height: 433px;

// Evertink colors
$evertink-gray: #90908B;
$evertink-beige: #FFFAEC;
