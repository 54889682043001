@use "../variables";

/* Staatiliste lehtede all asub "teised teenused" */
.other-services {
  position: relative;

  .tns-nav {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 1rem 0;

    button {
      border-radius: 50%;
      background: variables.$primary;
      padding: .3125rem;
      margin: 0 .3125rem;
      border: 0;
      cursor: pointer;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0.25rem #0090e7;
        outline-offset: 2px;
      }
    }
  }

  .tns-outer {
    margin: 0 3.375rem;
  }

  &__controls {
    top: 45%;
    position: absolute;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;

    .next, .prev {
      position: relative;
      padding: 0;
      background: 0;
      border: 0;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: variables.$primary;
      width: 2.5rem;
      height: 2.5rem;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 1.5625rem;

      &:focus {
        box-shadow: 0 0 0.25rem #0090e7;
        outline: .0625rem dotted transparent;
        outline-offset: .125rem;
      }

      &:hover {
        cursor: pointer;
        background-color: variables.$neutral-lighter-50;
      }
    }

    .prev .icon {
      left: 37%;
    }

    .next .icon {
      right: 37%;
    }
  }

  &__heading {
    font-weight: variables.$font-weight-normal;
    color: variables.$primary;
  }

  &__list {
    display: flex;
    margin-top: 2rem;

    &--carousel .other-services__item {
      padding: 1.5rem 1rem;

      .content {
        -webkit-box-orient: vertical;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1rem;
      }
    }
  }

  .bg-full--bluegradient {
    background: radial-gradient(98.54% 287.35% at 1.46% 0,variables.$primary-light 0,variables.$primary 100%),variables.$white;
  }

  &__item {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;

    .content {
      display: flex;
      -webkit-box-direction: normal;
    }
  }

  &--primary {
    li:hover {
      background: variables.$neutral-lighter-25;
    }

    .other-services__item {
      padding: 1.5rem 1rem;

      .content {
        -webkit-box-orient: vertical;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1rem;
      }
    }
  }

  &--secondary {
    .other-services__item {
      padding: 2rem 1.5rem;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }

      .content {
        -webkit-box-orient: horizontal;
        flex-direction: row;
        text-align: start;
        -webkit-box-align: center;
        align-items: center;
        padding: 0;
      }
    }
  }
}
