@use "variables";
@use "mixins";

@mixin responsive-block($val) {
    max-width: $val;
    flex-basis: $val;
}

$mobile-view-side-padding: 18px;

.staticContentContainer {
    .customlist .icon {
        margin-left: -30px;
    }

    ul.customlist, ul.customlist > li {
        list-style-image: url('/images/check-mark-yellow.svg');
        margin-left: 15px;
    }

    .custom-table {
        td {
            text-align: center;

            & > span {
                display: block;
                padding-bottom: 16px;
            }
        }
    }

    .accordion {
        width: 100%;

        &__item {
            width: 100%;
        }

        &__title {
            justify-content: space-between;
        }

        &__content {
            padding: 16px 80px;
        }
    }

    h2, h3, h4:not(.items-carousel__title) {
        padding: 24px 0 16px 0;
    }

    > h1, > h2, h3, h4:not(.items-carousel__title) {
        width: 100%;
    }

    h1 {
        padding: 24px 0;
    }

    h2 {
        padding-top: 20px;
    }

    h3 {
        padding-top: 10px;
    }


    & > p, ul, .row {
        padding-bottom: 32px;
    }

    p > span {
        display: block;
        padding-bottom: 8px;
    }

    .row-icon {
        color: variables.$primary;
    }

    .bulletlist > li {
        list-style: disc;
        margin-top: 8px;
    }

    .responsive-block {
        &-100, &-50, &-33, &-25 {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding: 16px;
        }
    }

    .carousel-card {
        &__code {
            bottom: 90px;
        }
    }
    .categories {
        &-background {
            background: variables.$supporting-08;
        }
    
        &__list {
            display: grid;
            box-shadow:
                0 1px 0 0 variables.$neutral-lighter-100,
                inset 0 1px 0 0 variables.$neutral-lighter-100;
            padding-bottom: 0;

            .icon-hover {
                fill: none;
                stroke-width: 1.5;
                stroke-linecap: round;
                stroke-linejoin: round;
                
                path, circle {
                    stroke: variables.$white;
                }

                .see-through {
                    fill: variables.$primary;
                }
            }
    
            .category {
                &__item {
                    background: variables.$white;
                    box-shadow:
                        inset 1px 0 0 0 variables.$neutral-lighter-100,
                        inset 0 1px 0 0 variables.$neutral-lighter-100;
    
                    &:hover {
                        box-shadow:
                            inset 1px 0 0 0 variables.$primary,
                            inset 0 1px 0 0 variables.$primary;
                        background: variables.$primary;
                        @if variables.$site-nova {
                            background: variables.$primary-light-10;
                        }
                        
                        a{
                            color: variables.$white;
                        }
                        .category-name {
                            position: relative;
                            bottom: -25px;
                        }
                    }
                }
    
                &__link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
    
                    .icon-hover {
                        display: none;
                    }
    
                    &:hover {
                        .icon-idle {
                            display: none;
                        }
    
                        .icon-hover {
                            display: block;
                        }
                    }
                }
    
                &-name {
                    padding: 10px 10px 0;
                    text-align: center;
                }
            }
        } 
    }
}

@media(max-width: variables.$responsive-break - 1) {

    .staticContentContainer {
        padding: 0 $mobile-view-side-padding;
        font-size: variables.$font-size-default;

        .responsive-block {
            &-100, &-50, &-33, &-25 {
                width: 100%;
            }
        }

        .row {
            flex-direction: column;

            &.buttons > * {
                margin-bottom: 10px;
            }

            .image {
                order: -1;
            }
        }

        .custom-list {
            padding: 0 32px;
        }

        .custom-table {
            tr {
                display: flex;
                flex-direction: column;
                margin: -$mobile-view-side-padding;
            }

            td {
                text-align: left;
                padding: 0 32px 32px 32px;
            }

            td:nth-child(1) {
                background-color: variables.$neutral-lighter-25;
            }
        }

        .other-services {
            display: none;
        }

        .accordion__content {
            padding: 0 48px;
        }

        i.row-icon {
            &.big, &.medium, &.small {
                font-size: 105px;
            }
        }

        span.row-icon, img.row-icon {
            &.big, &.medium, &.small {
                width: 100%;
                max-width: 375px;
            }
        }

        .border-top {
            border-top: 1px solid variables.$neutral-lighter-25;
        }

        .border-bottom {
            border-bottom: 1px solid variables.$neutral-lighter-25;
        }

        a {
            word-break: break-word;
        }

        h1, h2, h3, h4 {
            width: auto;
        }

        h1 {
            text-align: center;
            color: variables.$primary;
            font-weight: variables.$font-weight-normal;
        }

        h2 {
            padding: 10px 30px;
        }
        
        .consultation h2{
            padding: 10px 0;
        }

        h3, h4 {
            padding-left: 30px;
        }

        p {
            padding: 0 10px 0 30px;
        }

        .bulletlist {
            padding: 10px 10px 10px 30px;

            p {
                padding: 0 10px 0 0;
            }

            ::marker {
                color: variables.$secondary;
                font-weight: variables.$font-weight-bold;
            }
        }
        .categories-container .categories__list {
            grid-template-columns: repeat(2, 1fr);
            .category__link {
                height: 150px;
            }
        }
    }
}

@media(min-width: variables.$responsive-break) {

    .staticContentContainer {
        .responsive-block {
            &-100 {
                @include responsive-block(100%)
            }

            &-50 {
                @include responsive-block(50%)
            }

            &-33 {
                @include responsive-block(33%)
            }

            &-25 {
                @include responsive-block(25%)
            }
        }

        .custom-table {
            tr:nth-child(2n) {
                background-color: variables.$neutral-lighter-25;
            }

            td {
                padding: 0 128px 32px 128px;
            }
        }

        .row {
            &.buttons {
                justify-content: center;
            }

            &.buttons > * {
                margin-right: 10px;
            }
        }

        .accordion.accordion--mobile {
            .accordion__title {
                display: none;
            }

            .accordion__content {
                display: initial;
                background: none;
            }
        }

        .other-services {
            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 24px 16px;

                &__heading, &__read-more {
                    font-weight: variables.$font-weight-bold;
                    color: variables.$primary;
                    margin: 8px 0;
                }

                &__read-more::after {
                    margin-left: 8px;
                    content: "\003E";
                }
            }
        }

        i.row-icon {
            &.big {
                font-size: 150px;
            }

            &.medium {
                font-size: 105px;
            }

            &.small {
                font-size: 60px;
            }
        }

        span.row-icon, img.row-icon {
            &.big {
                width: 375px;
            }

            &.medium {
                width: 150px;
            }

            &.small {
                width: 60px;
            }
        }
        .categories-container {
            padding: 40px 150px;
    
            .categories__list {
                grid-template-columns: repeat(5, 1fr);
    
                .category__link {
                    height: 200px;
                }
            }
        }

        .categories-container {
            padding: 40px 150px;

            .categories__list {
                grid-template-columns: repeat(5, 1fr);

                .category__link {
                    height: 200px;
                }
            }
        }
    }
}

// new content templates 2023
.staticContentContainer {

    .border-bottom-blue {
        border-bottom: 1px solid variables.$content-page-border;
    }
    
    &.wideStaticPage {
        padding: 0;
    }

    .faq{
        &-description{
            width: 100%;
            max-width: 1216px;
            justify-self: center;
            justify-items: center;
            text-align: -webkit-center;
            background-color: variables.$neutral-lighter-21;
            padding: 20px 0;
            margin: 20px auto;
            border-radius: 10px;

            .accordion{
                &__item{
                    background-color: unset;
                }
                &__content{
                    padding: 16px 0;
                    text-align: left;
                }
                &__title{
                    line-height: 24px;
                }
            }
        }
        &-container{
            width: 750px;
        }
    }
    
    /*just for our example page*/
    hr.dashed {
        margin: 10px 0;
        border-bottom: 2px dashed variables.$secondary;
    }
    
    .header__grey{
        background: variables.$neutral-lighter-25;
        padding: 80px 30px;

        h1 {
            color: variables.$primary;
            padding-bottom: 0;
        }
        .header__pre-title {
            color: variables.$primary;
        }
    }

    .section--questions h2{
        margin: 0 16px;
        line-height: 40px;
        color: variables.$primary;
        font-size: variables.$font-size-3xl;
        font-weight: variables.$font-weight-bold;
        text-align: center;
    }

    .info-block{
        background-color: variables.$neutral-lighter-21;
        display: flex;
        margin: 40px 0;
        padding: 30px;
        border-radius: 8px;
        
        .image {
            align-self: start;
        }
        
        .text{
            padding: 0 20px;
            line-height: 24px;
            h2{
                padding: 0;
                line-height: 26px;
            }
        }
    }
    
    .central-container {
        background: variables.$white;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 650px;
        justify-self: center;
        padding: 40px 80px;
        border-radius: 8px;
        border: 1px solid variables.$neutral-lighter-50;
        margin: auto;
        line-height: 26px;
        
        h2 {
            padding-top: 0;
            color: variables.$primary;
            font-weight: variables.$font-weight-bold;
            font-size: variables.$font-size-3xl;
            line-height: 40px;
        }
    }

    header {
        background: variables.$primary;
        @if variables.$site-nova {
            background: #c8bcff;
        }
        padding: 40px 30px;

        .header {
            &__container {
                display: grid;
                grid-template-columns: 4fr 2fr;
                grid-template-rows: min-content;
                grid-template-areas:
            "pre-title circle"
            "title circle"
            "buttons circle";
                width: 100%;
                column-gap: 20px;

                &--secondary {
                    grid-template-columns: 3fr 2fr;
                    grid-template-areas:
                    "pre-title image"
                    "title image"
                    "after-title image"
                    "buttons image";

                    .header__pre-title {
                        color: variables.$primary;
                        font-weight: variables.$font-weight-bold;
                        font-size: variables.$font-size-sm;
                    }
                    
                    .header__image {
                        max-height: 470px;
                    }
                }
            }
            
            &__pre-title {
                grid-area: pre-title;
                align-self: end;
                color: variables.$primary-lighter;
                @if variables.$site-nova {
                    color: variables.$primary-darker;
                }
                font-size: 21px;
                font-weight: 400;
                line-height: 24px;
            }
            
            &__image {
                grid-area: image;
                justify-self: end;
            }
            
            &__after-title {
                grid-area: after-title;
                color: variables.$primary;
                font-size: variables.$font-size-lg;
                line-height: 30px;
                
                &.text-black{
                    color: variables.$black-500;
                }

                .font-2sm{
                    font-size: variables.$font-size-2sm
                }
            }

            &__buttons {
                grid-area: buttons;
                display: flex;
                align-items: center;

                .button {
                    min-width: 330px;
                    margin: 4px;
                    padding: 2px 20px;
                    text-align: center;
                }
            }

            &__circle {
                grid-area: circle;
                width: 237px;
                height: 237px;
                border-radius: 50%;
                background: variables.$primary-darker;
                @if variables.$site-nova-or-avi {
                    background: variables.$color-bright-sun;
                }
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                color: variables.$white;

                i, img {
                    height: 86px;
                    font-size: 86px;
                    @if variables.$site-nova {
                        filter: brightness(0) saturate(100%) invert(13%) sepia(45%) saturate(1854%) hue-rotate(222deg) brightness(84%) contrast(102%); // primary-darker
                    }
                }
            }
            
            &__large-image-container {
                height: 400px;
                border-radius: 10px;
                overflow: hidden;
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }

        h1 {
            grid-area: title;
            color: variables.$white;
            @if variables.$site-nova {
                color: variables.$primary-darker;
            }
            font-size: 40px;
            font-weight: 400;
            line-height: 43px;
            letter-spacing: 0em;
            text-align: left;
        }

        //small header without icons
        &.header--small {
            padding: 18px 24px;
        }

        .buttons {
            display: flex;
            height: max-content;
            max-width: 400px;
            margin-top: 21px;

            .button {
                margin-right: 8px;
                padding: 8px 20px;
                text-align: center;
                height: 100%;
                width: 100%;
                white-space: normal;
                max-width: 400px;
            }

            .button-secondary {
                background: variables.$white-500;
                border: 1px solid variables.$neutral-lighter-100;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.5px;
            }
        }
    }

    .header__statistics {
        display: flex;
        place-content: center;
        background-color: variables.$primary;
        .statistics-item{
            height: 95px;
            width: 236px;
            background-color: rgba(255, 255, 255, .11);
            margin: 20px 5px;
            border-radius: 8px;
            align-content: center;
            text-align: -webkit-center;
            color: variables.$primary-light;
            padding: 20px;
            display: grid;
            grid-template-rows: 1fr 2fr;
            line-height: 20px;

            h2{
                padding-top: 0;
                padding-bottom: 10px;
                color: variables.$white;
                font-weight: variables.$font-weight-bold;
                font-size: variables.$font-size-xl;
                line-height: 24px;
                border-bottom: 1px solid rgba(255, 255, 255, .11);
                text-align: center;
            }
        }
    }

    .header__white{
        header {
            background: variables.$white;

            h1 {
                color: variables.$primary;
            }
            
            .header{
                color: variables.$primary;
                &__pre-title{
                    font-size: 16px;
                    color: variables.$primary;
                    font-weight: variables.$font-weight-bold;
                }
                &__container {
                    grid-template-columns: 1fr 1fr;
                }
            }

            .title{
                display: unset;
                &-mobile{
                    display: none;
                }
            }
            
            .text{
                line-height: 24px;
            }
        }
    }

    section {
        padding: 20px 0;

        h2 {
            line-height: 40px;
        }
        
        &.slim {
            padding: 22px 0;
        }

        &.unpadded {
            padding: 0;
        }

        &.section {
            &--gray {
                background: variables.$neutral-lighter-25;

                .categories-background {
                    background: variables.$neutral-lighter-25;
                }

                article {
                    .image {
                        background: variables.$neutral-lighter-50;

                        i {
                            color: variables.$primary-dark;
                        }
                    }
                }
            }

            &--dark-gray {
                background: variables.$neutral-lighter-50;
            }

            &--light-blue {
                background: variables.$primary-lighter-10;
            }

            &--content-dark {
                article {
                    .image {
                        background: variables.$primary;

                        i {
                            color: variables.$white;
                        }
                    }
                }
            }

            .read-more-content {
                display: none;
                color: variables.$primary;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-decoration: underline;
            }

        }

        &.mirrored {
            article:not(.carousel-card) {
                grid-template-columns: 4fr 6fr;
                grid-template-areas: "image text";
            }
        }

        &.salesman {
            padding: 46px 0 0 0;
        }

        .big-primary-light {
            color: variables.$primary-light;
            font-size: variables.$font-size-7xl;
        }
        
        .consultation {
            display: grid;
            grid-template-columns: 6fr 4fr;
            grid-template-areas: "text image";
            line-height: 24px;
            
            &--middle {
                justify-items: center;
                grid-template-columns: 1fr;
                text-align: center;

                .wrapper{
                    .buttons{
                        justify-content: center;
                        margin-top: 0;
                    }
                }
            }
            
            .text {
                padding-right: 60px;
            }
            
            h3 {
                font-size: 24px;
                color: variables.$primary;
            }

            h2 {
                font-size: 34px;
                color: variables.$primary;
            }
            
            &--thirds {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 50px;

                h4 {
                    color: variables.$primary;
                    padding: 16px 0;
                    font-size: 20px;
                }
                
                .image-container {
                    height: 200px;
                    width: 300px;
                    border-radius: 10px;
                    overflow: hidden;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }
                }
            }
            
            .span-all-columns {
                grid-column: 1/-1;
            }
            
            .salesman{
                
                &__content-wrapper{
                    grid-template-columns: 1fr;
                    overflow: hidden;
                    height: 260px;
                }
                &__background{
                    position: absolute;
                    width: 480px;
                    height: 240px;
                    margin: 0 auto;
                    z-index: 0;
                    bottom: 0;
                    background: variables.$white;
                    border-radius: 400px 400px 0 0;
                }
                
                &__image{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .buttons {
                display: flex;
                height: max-content;
                margin-top: 21px;

                .button {
                    margin-right: 8px;
                    padding: 8px 20px;
                    text-align: center;
                    height: 100%;
                    width: 100%;
                    white-space: nowrap;
                }

                .button-secondary {
                    background: variables.$white-500;
                    border: 1px solid variables.$neutral-lighter-100;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
            }
        }

        article:where(.one-one-grid) {
            grid-template-columns: 1fr 1fr !important;
        }

        article:not(.carousel-card) {
            display: grid;
            grid-template-columns: 6fr 4fr;
            grid-template-areas: "text image";
            background: variables.$white;
            border: 1px solid variables.$neutral-lighter-100;
            border-radius: 8px;
            overflow: hidden;
            min-height: 320px;
            
            .text {
                grid-area: text;
                padding-top: 40px;

                h2 {
                    border-bottom: 1px solid variables.$secondary;
                    padding: 0 40px 30px 40px;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 40px;
                    color: variables.$primary;
                }
                
                .grey-bottom-border {
                    border-bottom: 1px solid variables.$neutral-lighter-25;
                }

                h3 {
                    font-size: variables.$font-size-lg;
                    font-weight: variables.$font-weight-bold;
                    padding: 0 40px 30px 40px;
                }
            }

            .content {
                padding: 40px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: variables.$neutral-darker;

                .buttons {
                    display: flex;
                    height: 46px;
                    margin-top: 21px;

                    .button {
                        margin-right: 8px;
                        padding: 2px 20px;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                        white-space: nowrap;
                    }


                    .button-primary,.button--disabled {
                        max-width: 295px;
                    }

                    .button-secondary {
                        background: variables.$white-500;
                        border: 1px solid variables.$neutral-lighter-100;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                    }
                }

            }

            .image {
                grid-area: image;
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                background: variables.$neutral-lighter-50;
                height: 100%;
                overflow: hidden;

                i, img {
                    height: 122px;
                    font-size: 122px;
                }

                &--large {
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }

        }

        .dots {
            padding: 12px 32px;

            li {
                list-style: unset;
                margin-bottom: 10px;
            }
        }

        .content {
            padding: 0 36px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            &--light-blue {
                background: variables.$primary-lighter-10;
                border-radius: 8px;
                padding: 40px 62px;
            }

            &--narrow {
                padding: 0 126px;
            }


            .cta-header {
                font-weight: 400;
                font-size: 21px;
                line-height: 26px;
                letter-spacing: 0.5px;
            }
        }

        .questions {
            display: flex;

            a {
                text-decoration: underline;
            }

            >:not(:last-child) {
                margin-right: 10px;
            }
            >:not(:first-child) {
                margin-left: 10px;
            }

            .question{
                background-color: variables.$white;
                width: 100%;
                min-height: 250px;
                border-radius: 8px;

                .number{
                    padding: 30px 20px 20px 20px;
                }

                .text{
                    padding: 0 20px 30px 20px;
                    font-size: 16px;
                    line-height: 24px;
                }

                h3 {
                    font-size: variables.$font-size-lg;
                    font-weight: variables.$font-weight-bold;
                    padding: 0;
                }

                &-grey-block{
                    background-color: variables.$neutral-lighter-25;
                    min-height: 320px;

                    h3 {
                        text-align: -webkit-center;
                    }

                    .image{
                        justify-self: center;
                        text-align: -webkit-center;
                        margin: 20px 0 10px 0;
                    }
                }
            }
        }

        .question-info,.price-list-info{
            display: flex;
            background-color: variables.$white;
            align-items: center;
            margin: 40px 0;
            border-radius: 8px;
            font-size: 16px;
            .icon {
                padding: 10px 10px 10px 20px;
            }
            span{
                line-height: 24px;
            }
        }

        .accordion {
            &__item {
                margin: 10px 0;
                background: variables.$white-500;
                border: 1px solid variables.$neutral-lighter-100;
                border-radius: 8px;
                overflow: hidden;

                .accordion__title::after {
                    right: 20px;
                }
            }

            &__title {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 21px;
                line-height: 24px;
                color: variables.$primary;
                padding: 20px;
            }

            &__content {
                padding: 20px;
            }
        }

        &.salesman__alternative {
            .salesman{
                &__content{
                    justify-content: right;
                    &-wrapper{
                        grid-template-columns: 5fr 3fr;
                        grid-template-areas: "content img";
                        width: 100%;
                    }
                }
                &__background{
                    width: 550px;
                    height: 290px;
                    margin: 0 auto;
                    bottom: 0;
                    right: 60px;
                    background: variables.$neutral-lighter-25;
                }
                &__text{
                    margin-right: 250px;
                    .button{
                        font-weight: variables.$font-weight-bold;
                        font-size: variables.$font-size-lg;
                        line-height: 16px;
                        padding: 8px 64px;
                    }
                }
                &__pre-title{
                    font-weight: variables.$font-weight-bold;
                    font-size: variables.$font-size-3xl;
                    line-height: 40px;
                    margin-bottom: 20px;
                }
            }
        }
        
        .salesman {
            &__content {
                display: flex;
                position: relative;
                justify-content: center;

                &-wrapper {
                    margin: 0 auto;
                    display: grid;
                    grid-template-columns: 2fr 3fr;
                    grid-template-areas: "img content";
                    z-index: 1;
                }
            }

            &__background {
                position: absolute;
                width: 682px;
                height: 356px;
                margin: 0 auto;
                z-index: 0;
                bottom: 0;

                background: variables.$primary-lighter-10;
                border-radius: 600px 600px 0 0;
            }

            &__image {
                grid-area: img;
                height: 339px;
            }

            &__text {
                grid-area: content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 24px 0 0 12px;
            }

            &__pre-title {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: variables.$primary;
            }

            &__button {
                .button {
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                    padding: 12px 40px;
                    margin: 24px 0;
                }
            }

            &__name {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: variables.$primary;
                margin-bottom: 6px;
            }

            &__sub-title {
                max-width: 50%;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: variables.$neutral;
            }
        }

        h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
            color: variables.$primary;
        }

        table {
            &.comparison-table {
                width: 100%;
                border-collapse: collapse;
                background: variables.$white-500;
                border-radius: 8px;

                th {
                    padding: 25px 2px;

                    &:last-of-type {
                        padding-right: 25px;
                    }

                    &.header-cell {
                        padding-left: 45px;
                        width: 100%;
                        text-align: left;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: 0.5px;
                        color: variables.$primary;

                        &.black {
                            color: variables.$black-500;
                            font-size: 16px;
                            line-height: 22px;
                            font-weight: 600;
                        }
                    }
                }

                tr {
                    &.comparison-table__row {
                        border-top: 1px solid variables.$neutral-lighter-100;
                    }

                    &:has(div.accordion__item--active ) {
                        background-color: variables.$primary-lighter-10;
                    }

                    td {
                        padding: 8px 12px;
                    }
                }

                .fa-check {
                    color: variables.$primary;
                }

                .accordion {
                    &__item {
                        border: none;
                        background: unset;

                        .fa-chevron-up {
                            display: none;
                        }

                        &--active .fa-chevron-up {
                            display: block;
                        }

                        &--active .fa-chevron-down {
                            display: none;
                        }
                    }

                    &__title {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                        justify-content: initial;
                        padding: 0;
                    }

                    &__content {
                        padding: 22px 28px;
                    }
                }

                .square {
                    height: 69px;
                    width: 144px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;
                    border-radius: 8px;
                }

                .light-blue {
                    background: variables.$primary-lighter-50;
                    color: variables.$black-500;
                }

                .dark-blue {
                    background: variables.$primary;
                    color: variables.$white-500;
                }

                .green {
                    background: #28A59D;
                    color: variables.$white-500;
                }

                &--bottom {
                    margin-top: 12px;
                }
            }

            &.insurance-table {
                width: 100%;
                text-align: center;
                border-collapse: collapse;
                margin-top: 32px;

                tr {
                    border-bottom: 1px solid variables.$neutral-lighter-100;

                    td, th {
                        padding: 10px;
                    }
                }
            }
        }

        .buttons {
            padding-top: 35px;

            &--centered {
                display: flex;
                justify-content: center;

                .static-button {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.5px;
                    padding: 4px 20px;
                }
            }
        }

        .tabs-menu {
            ul {
                padding-bottom: unset;
            }
        }

        .kkk-panel {
            .accordion__title {
                font-size: 18px;
            }
        }

        .smart-rent {

            &__choose-rental {

                display: grid;
                grid-template-areas:
                'lt r'
                'lb r';
                justify-content: space-between;

                .left {
                    grid-area: lt;
                    align-content: center;
                }

                .left-bottom {
                    grid-area: lb;
                }

                .right {
                    grid-area: r;
                    text-align: end;

                    img {
                        width: 90%;
                        content: url('/images/smartrent/smart_rent_desktop.png');
                    }
                }

                .text-1 {
                    color: variables.$primary;
                    font-size: variables.$font-size-xl;
                    font-weight: variables.$font-weight-bolder;
                    padding: 5px 0;
                }

                .text-2 {
                    color: variables.$primary;
                    font-size: variables.$font-size-7xl;
                    font-weight: variables.$font-weight-bolder;
                    padding: 5px 0;
                }

                .text-3 {
                    font-size: variables.$font-size-xl;
                    font-weight: variables.$font-weight-normal;
                    padding: 5px 0;
                }

                .buttons {
                    padding: 0;

                    .button {
                        align-items: center;
                        text-align: center;
                        padding: 14px 44px;
                    }
                }
            }

            &__description, &__availability {
                width: 70%;
                padding: 50px 0;
                color: variables.$primary;
                font-size: variables.$font-size-xl;
                font-weight: variables.$font-weight-bolder;
            }

            &__benefits {

                padding-bottom: 20px;

                .title {
                    color: variables.$primary;
                    font-size: variables.$font-size-4xl;
                    font-weight: variables.$font-weight-bolder;
                    margin: 24px 0 40px 0;
                }

                .list {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 10px;                    

                    .list-item {
                        display: flex;
                        flex-direction: column;
                        width: 220px;

                        .icon {
                            height: 120px;
                        }

                        .text-short {
                            flex-grow: 1;
                            color: variables.$primary;
                            font-size: variables.$font-size-lg;
                            font-weight: variables.$font-weight-bolder;
                            padding: 20px 0 15px 0;
                        }

                        .low-cost {
                            content: url('/images/smartrent/low_cost.svg');
                        }

                        .period {
                            content: url('/images/smartrent/rental_period.svg');
                        }

                        .delivery {
                            content: url('/images/smartrent/delivery.svg');
                        }

                        .mind {
                            content: url('/images/smartrent/mind.svg');
                        }

                        .waste {
                            content: url('/images/smartrent/waste.svg');
                        }
                    }
                }
            }

            &__how-it-works {

                .title {
                    color: variables.$primary;
                    font-size: variables.$font-size-4xl;
                    font-weight: variables.$font-weight-bolder;
                    margin: 24px 0;
                }

                .sub-title {
                    font-size: variables.$font-size-md;
                    line-height: 30px;
                    a {
                        text-decoration: underline;
                    }
                }

                .list {
                    margin: 20px 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 10px;

                    > *:not(:first-child) {
                        margin-left: 30px;
                    }


                    .list-item {
                        display: flex;
                        flex-direction: column;
                        width: 400px;

                        .step {
                            color: variables.$primary-light;
                            font-size: 60px;
                            line-height: 60px;
                        }

                        .text-short {
                            flex-grow: 1;
                            color: variables.$primary;
                            font-size: variables.$font-size-lg;
                            font-weight: variables.$font-weight-bolder;
                            padding: 0 0 20px 0;
                        }
                        
                        .text-long {
                            line-height: 24px;
                        }
                    }
                }
            }

            &__affordable-fee {

                width: 60%;
                margin: auto;

                padding: 40px 0;

                .title {
                    color: variables.$primary;
                    font-size: variables.$font-size-4xl;
                    font-weight: variables.$font-weight-bolder;
                    margin: 24px 0;
                    text-align: center;
                }

                .buttons {
                    padding: 20px 0;
                    text-align: center;

                    .button {
                        align-items: center;
                        text-align: center;
                        padding: 14px 44px;
                    }
                }
            }

            &__products {

                .title {
                    color: variables.$primary;
                    font-size: variables.$font-size-4xl;
                    font-weight: variables.$font-weight-bolder;
                    margin: 24px 0 40px 0;
                }

                .button {
                    background-color: #fff;
                    border: solid 2px #f1f3f6;
                    border-radius: 100px;
                    font-weight: 400;
                }

                .button:hover {
                    background-color: #d6ab00;
                }
            }
        }
        
        &.section--secondary-blue{
            background-color: variables.$primary-light;
            display: flex;
            justify-content: center;
            
            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .title {
                    color: variables.$white;
                    font-size: variables.$font-size-4xl;
                    font-weight: variables.$font-weight-bolder;
                }
            }
        }
        
        .big-title {
            color: variables.$primary;
            font-size: variables.$font-size-4xl;
            font-weight: variables.$font-weight-bolder;
            margin-top: 32px;
            margin-bottom: 8px;
        }
        
        .grid-3x3 {
            &-container{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 30px;
                width: 1200px;
                height: fit-content;
                margin: 30px auto;

                .grid-item {
                    display: flex;
                    justify-content: left;
                    align-items: start;
                    color: variables.$black-500;
                    flex-direction: column;
                    margin: 10px 0;
                    line-height: 24px;
                    
                    .item-title {
                        color: variables.$primary;
                        font-size: variables.$font-size-lg;
                        font-weight: variables.$font-weight-bold;
                    }
                    
                    a {
                        color: variables.$primary;
                        text-decoration: underline;
                    }
                }
            }
            
            .title {
                color: variables.$primary;
                font-size: variables.$font-size-4xl;
                font-weight: variables.$font-weight-bolder;
                margin: 24px 0 40px 0;
            }

            .return-90-day {
                content: url('/images/loyalcustomer/90-day-return.svg');
            }
            
            .best-price-guarantee {
                content: url('/images/loyalcustomer/best-price-guarantee.svg');
            }
            
            .digital-history {
                content: url('/images/loyalcustomer/digital-history.svg');
            }
            
            .free-post-package-shipping {
                content: url('/images/loyalcustomer/free-post-package-shipping.svg');
            }
            
            .friendly-prices {
                content: url('/images/loyalcustomer/friendly-prices.svg');
            }
            
            .partner-offers {
                content: url('/images/loyalcustomer/partner-offers.svg');
            }
            
            .personal-offers {
                content: url('/images/loyalcustomer/personal-offers.svg');
            }
            
            .video-consultation {
                content: url('/images/loyalcustomer/video-consultation.svg');
            }
        }
    }
}

@media(max-width: variables.$responsive-break - 1) {

    .staticContentContainer {
        section:not(.items-carousel) {
            padding: 20px;

            .smart-rent {
                
                &__benefits {
                    .list {
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                }
                
                &__products {
                    .items-carousel__list {
                        li:nth-child(n+4 of .tns-slide-active) {
                            display: none;
                        }

                        .items-carousel__item {
                            margin-bottom: 0;

                            &:not(.tns-slide-active) {
                                display: none;
                            }

                            .carousel-card {
                                margin: 0 10px 10px;
                            }
                        }
                    }    
                }                
            }
            .consultation--thirds {
                grid-template-columns: auto;
            }
        }

        .faq-description{
            width: 90%;
            .faq-container{
                max-width: 100%;
                .accordion{
                    &__title{
                        padding-right: 30px;
                        height: fit-content !important;
                        text-align: left;
                    }
                    &-toggle-handle{
                        padding-bottom: 0;
                    }
                }
                
                h3{
                    color: variables.$primary;
                }
            }
        }
        
        section.salesman__alternative {
            padding-bottom: 0;
            .salesman{
                &__text{
                    margin: 0;
                    padding-left: 0;
                }
                &__content{
                    justify-content: center;
                    &-wrapper{
                        grid-template-columns: 100%;
                        grid-template-areas: "content" "img";
                        width: 100%;
                        justify-items: center;
                    }
                }
                &__background{
                    right: unset;
                }
                &__button{
                    width: 100%;
                    max-width: 350px;
                    align-self: center;
                    text-align: center;
                }
            }
        }

        section.items-carousel .items-carousel__wrapper .items-carousel__list{
            padding: 0 18px;
        }
        
        .header__statistics{
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .statistics-item{
                margin: 5px;
                max-width: 400px;
                width: 70%;
            }
        }

        section.items-carousel {
            padding: 0;
        }
        
        section.section--questions.section--gray,section.section--questions{
            padding: 0 0 1px 0;
            background-color: variables.$white;

            .title{
                max-width: 100%;
                background-color: variables.$white;
                padding-top: 30px;
            }

            .questions {
                flex-direction: column;
                .question{
                    margin: 0;
                    border-radius: 0;
                    
                    .text{
                        padding-bottom: 0;
                    }
                }
                
                &-grey-block{
                    flex-direction: column;
                    align-items: center;
                    .question{
                        margin: 10px;
                        width: -webkit-fill-available;
                        min-height: unset;
                        border-radius: 8px;
                        .text {
                            padding: 0 20px 30px 20px;
                        }
                    }
                }
            }
            
            .question-grey-block {
                max-width: 600px;
            }
        }

        .grid-3x3 .grid-3x3-container {
            grid-template-columns: 1fr 1fr;
            width: 100%;
        }
    }
    
    #sass .staticContentContainer .title{
        max-width: 100%;
        background-color: variables.$white;
        padding-top: 30px;
    }
    
    .container.view-all{
        margin: 10px;
    }
    
    .container .image.image--large.auto-baseline{
        align-items: baseline;
        img {
            width: auto;
        }
    }
}

// new content templates 2023 Responsive
@media(max-width: variables.$mobile-break - 1) {
    .staticContentContainer {

        .grid-3x3 .grid-3x3-container {
            grid-template-columns: 1fr;
            width: 100%;
        }
        
        .header__grey {
            padding: 20px 30px;
        }
        
        .info-block {
            flex-direction: column;
            margin-top: 0;
            .image {
                margin: auto;
                padding-bottom: 10px;
            }
        }
        
        header {
            padding: 18px 20px;

            .header__container {
                display: grid;
                grid-template-areas:
                "pre-title"
                "title"
                "circle"
                "buttons";
                grid-template-columns: 100%;
                grid-template-rows: min-content;
                padding: 26px 0;
                
                &--secondary{
                    grid-template-areas:
                        "pre-title"
                        "title"
                        "after-title"
                        "image"
                        "buttons";
                    
                    .header__image {
                        justify-self: center;
                        padding: 30px 0;
                        
                        img {
                            max-width: 100%;
                        }
                    }
                    
                    .header__buttons .button{
                        min-width: 300px;
                    }
                    
                    .header__after-title {
                        padding-top: 20px;
                    }
                    
                    &-v2 {
                        grid-template-areas:
                        "pre-title"
                        "title"
                        "after-title"
                        "buttons"
                        "image";
                        padding: 0;
                        
                        .header__buttons, h1 {
                            margin-top: 20px;
                        }
                        
                        .header__image {
                            padding: 0;
                            img {
                                object-fit: contain;
                                height: unset;
                            }
                        }
                    }
                }
            }
            
            &.header__grey {
                padding: 0;
                
                button.button {
                    margin: 0;
                }
                
                h1, .header__pre-title, .header__after-title {
                    padding: 0 20px;
                }
                
                
            }

            .header {
                &__circle {
                    width: 100px;
                    height: 100px;
                    margin: 0 auto 20px;

                    i, img {
                        height: 50px;
                        font-size: 50px;
                    }
                }

                &__buttons {
                    flex-direction: column;

                    .button {
                        width: 100%;
                    }
                    
                    .buttons{
                        justify-content: center;
                    }
                }
            }
        }

        .header__white .header{
            &__container{
                display: flex;
                flex-direction: column-reverse;

                .button{
                    text-wrap-mode: wrap;
                }

                .title{
                    display: none;
                    &-mobile{
                        display: unset;
                    }
                }

                .buttons{
                    justify-content: center;
                }
            }
            &__large-image-container{
                height: 300px;
                margin-bottom: 16px;
            }
        }
        
        .central-container{
            padding: 40px 20px;
            text-align: center;
            h2 {
                padding-left: 0;
            }
        }
        
        .info-block{
            padding: 30px 10px;
        }
        
        section {
            .m-smaller-title {
                font-size: variables.$font-size-2xl;
            }

            .container{
                .title {
                    padding: 0;
                }

                .one-one-grid{
                    grid-template-columns: 1fr !important;

                    .text h3 {
                        padding-left: 16px;
                    }
                }

                .question-grey-block .text{
                    justify-items: center;
                }
            }
            
            &.salesman__alternative {
                .container{
                    .salesman__background{
                        height: 180px;
                        max-width: 360px;
                    }
                }
            }
            
            &.section--secondary-blue .container .title{
                font-size: variables.$font-size-2xl;
                text-align: center;
            }
        }
        
        section:not(.items-carousel) {
            padding: 20px;
            
            .m-left {
                padding: 0;
                margin: 0;
                text-align: left;
            }

            &:last-child {
                padding-bottom: 0;
            }

            h3 {
                font-size: 21px;
                line-height: 26px;
                padding: 16px 0;
            }

            &.slim {
                padding: 13px 36px;
            }

            &.mirrored article:not(.carousel-card),
            article:not(.carousel-card) {
                grid-template-columns: 1fr;
                grid-template-rows: 170px 1fr;
                grid-template-areas: 
                    "image" 
                    "text";

                .text {
                    h2 {
                        font-size: 21px;
                        line-height: 24px;
                        padding: 8px 16px;
                    }
                }

                .content {
                    padding: 8px 16px;

                    .buttons {
                        flex-direction: column;
                        height: unset;
                        row-gap: 8px;

                        .button-primary {
                            max-width: unset;
                        }

                        .button {
                            width: 100%;
                            min-height: 46px;
                        }
                    }
                }


                .image {
                    i, img {
                        height: 90px;
                        font-size: 90px;
                    }

                    &--large {
                        img, {
                            height: unset;
                            width: 100%;
                        }
                    }
                }
            }

            .accordion {
                &__item {
                    .accordion__title {
                        padding: 20px;

                        &::after {
                            right: 20px;
                        }
                    }
                }

                &__content {
                    padding: 0;

                    .panel-left {
                        height: 100%;
                        background: rgba(38, 46, 68, 0.8);
                    }

                    .panel-right {
                        .responsive-panel__back {
                            padding: 10px 18px;
                            background: variables.$white;
                        }

                        .responsive-panel {
                            background: variables.$neutral-lighter-25;
                            padding: 32px 24px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 28px;
                            color: variables.$primary;
                        }
                    }
                }
            }

            .salesman {
                &__content-wrapper {
                    place-items: center;
                }

                &__background {
                    width: 100%;
                    height: 250px;
                }

                &__text {
                    padding-top: 0;
                }

                &__image {
                    height: 228px;
                    align-self: end;
                }

                &__button {
                    .button {
                        margin: 14px 0;
                    }
                }
            }

            .content {
                padding: 16px 20px 0 20px;

                .responsive-block {
                    &-50, &-25, &-33, &-100 {
                        padding: unset;
                    }
                }
                
                button {
                    width: 100%;
                }
            }

            article {
                .text {
                    padding-bottom: 20px;
                }
            }

            &.section--read-more {
                .content {
                    max-height: 180px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                }

                .read-more-content {
                    padding: 12px 20px;
                    display: block;
                }
            }

            .buttons {
                padding: 16px 0;

                .static-button {
                    width: 100%;
                }
            }

            table {
                &.comparison-table {
                    tr {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-template-areas:
                                "trtitle trtitle trtitle"
                                "button1 button2 button3";

                        td:nth-child(1),
                        th:nth-child(1) {
                            grid-area: trtitle;
                            padding-bottom: 0;
                        }

                        td {
                            padding-bottom: 20px;
                        }
                    }

                    th {
                        &.header-cell {
                            width: unset;
                            padding: 15px;
                        }

                        &:last-of-type {
                            padding-right: 15px;
                        }


                        &:nth-child(2) {
                            padding-left: 15px;
                        }
                    }


                    .square {
                        width: unset;
                    }
                }
            }

            // Special cases (insurances pages)
            &.kasko-section {
                padding: 0;

                .content {
                    padding: 0;
                }
            }

            &.responsive-gray {
                background: #FAFBFC !important; //one-off, not in variables
            }

            &.kasko-tabs {
                padding: 0;

                section {
                    padding: 0;
                }

                .content {
                    padding: 0;
                }

            }

            &.header-tabs {
                background: variables.$neutral-lighter-25;
                padding: 35px 0;

                .tabs-menu__item {
                    padding: 16px;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    display: grid;
                    grid-template-columns: fit-content(100%) fit-content(100%);
                    border: 1px solid variables.$neutral-lighter-100;
                    border-radius: 8px;
                    margin-bottom: 8px;
                    grid-gap: 16px;
                    background: variables.$white-500;

                    h2 {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }

            .triple {
                grid-area: button2;
                place-self: center;
            }

            .smart-rent {

                &__choose-rental {
                    justify-content: space-around;
                    grid-template-areas:
                    'lt'
                    'r'
                    'lb';

                    .right {
                        img {
                            width: 100%;
                            text-align: center;
                            content: url('/images/smartrent/smart_rent_mobile.png');
                        }
                    }

                    .left-bottom {
                        justify-self: center;
                    }
                }

                &__description, &__availability {
                    width: 100%;
                    padding: 50px 0;
                    color: variables.$primary;
                    font-size: variables.$font-size-xl;
                    font-weight: variables.$font-weight-bolder;
                }

                &__benefits {
                    .list {
                        flex-direction: column;

                        .list-item {
                            width: auto;
                        }

                        .icon {
                            text-align: center;
                        }

                        .text-short {
                            text-align: left;
                        }

                        .text-long {
                            text-align: left;
                            padding-bottom: 20px;
                        }
                    }
                }

                &__how-it-works {
                    .list {
                        flex-direction: column;
                        
                        .list-item {
                            width: auto;
                            margin-left: unset;
                        }

                        .text-long {
                            margin-bottom: 20px;
                        }
                    }
                }

                &__affordable-fee {

                    width: 100%;
                    padding: 20px 0;

                    .title {
                        color: variables.$primary;
                        font-size: variables.$font-size-4xl;
                        font-weight: variables.$font-weight-bolder;
                        margin: 24px 0;
                        text-align: left;
                    }

                    .buttons {
                        padding: 20px 0;
                        text-align: center;

                        .button {
                            align-items: center;
                            text-align: center;
                            padding: 14px 44px;
                        }
                    }
                }

                &__products {
                    .items-carousel__list {
                        li:nth-child(n+4 of .tns-slide-active) {
                            display: none;
                        }

                        .items-carousel__item {
                            margin-bottom: 0;

                            &:not(.tns-slide-active) {
                                display: none;
                            }

                            .carousel-card {
                                margin: 0 10px 10px;
                            }
                        }
                    }
                }
            }
            
            .consultation {
                display: flex;
                flex-direction: column;
                
                &--thirds{
                    .image-container{
                        width: 100%;
                        height: 250px;
                    }
                }
                
                .text{
                    padding-right: 0;
                    padding-bottom: 20px;
                }
                
                .button{
                    text-wrap-mode: wrap;
                }
                
                .salesman {
                    &__background{
                        height: 170px;
                        max-width: 350px;
                    }
                    
                    &__content-wrapper{
                        height: 180px
                    }
                }

                &--middle {
                    justify-items: left;
                    text-align: left;

                    .wrapper{
                        .buttons{
                            justify-content: left;
                        }
                    }
                }
            }
        }

        .questions .question .text h3:not(.m-left){
            text-align: center;
        }
    }
}