@use "../variables";
@mixin purchase-info {

    .ps1 {
        width: 8%;
        font-size: 50px;
    }

    .ps2 {
        width: 92%;
    }

    .ho-table {
        width: 100%;
        text-align: left;
    }

    .ho-tr {
        height: 30px;
    }

    .ho1 {
        width: 33%;
    }

    .ho2 {
        width: 21%;
    }

    .new-service {
        color: variables.$white;
        background-color: red;
        border-radius: 10px;
        font-size: 11px;
        padding: 2px 5px;
        margin-top: -3px;
    }

    .din-table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
    }

    .ho-table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
    }

    .din-tr {
        width: 100%;
    }

    .din-th1 {
        width: 24%;
    }

    .din-th2 {
        width: 54%;
    }

    .din-th3 {
        width: 17%;
    }

    .din-table tr {
        height: 90px;
    }

    .din-table tr:nth-child(even) {
        background: variables.$white;
    }

    .ho-table tr {
        height: 45px;
    }

    .ho-table tr:nth-child(even) {
        background: variables.$white;
    }

    .purchase-methods {
        margin: 10px 0;

        .purchase-method {
            display: flex;
            width: 100%;
            border-radius: 10px;
            border: 1px solid rgb(172, 241, 172);
            background-color: #e0fccb;
            margin-bottom: 30px;

            &.service {
                border: 1px solid variables.$secondary-light;
                background-color: variables.$secondary-lighter;
            }

            &.call-purchase {
                display: none;
            }

            .method-icon {
                margin: auto;
                padding: 0 14px;

                i {
                    font-size: 50px;
                }
            }

            .method-content {
                padding: 16px 0;
            }
        }
    }

    .accordion-container {

        .accordion {
            width: initial;

            &.ho-accordion {
                display: none;
            }
        }
    }
}

@mixin purchase-info_responsive {


    .purchase-methods {
        .purchase-method {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 0;
            border: 0;
            background-color: #e0fccb;
            margin-bottom: 15px;

            p, h3 {
                padding: 0;
            }

            &.service {
                background-color: variables.$secondary-lighter;
            }

            &.call-purchase {
                display: none;
            }

            .method-icon {
                margin: auto;
                padding: 20px 0;

                i {
                    font-size: 50px;
                }
            }

            .method-content {
                padding: 0 40px 20px 40px;
            }
        }
    }

    .accordion-container {
        margin-top: 20px;
        padding: 10px 0;
        background-color: variables.$neutral-lighter-25;

        .accordion {
            width: initial;

            &.ho-accordion {
                display: none;
            }

            margin: 10px 20px;
            padding: 0;

            .accordion__item {

                p {
                    padding: 0;
                }

                .accordion__title {
                    padding: 10px;
                    background-color: variables.$white;
                    text-align: center;

                    i {
                        display: none;
                    }
                }

                .accordion__title::after {
                    content: "+";
                    font-weight: variables.$font-weight-normal;
                    font-size: variables.$font-size-5xl;
                    padding: 0 10px;
                }

                &--active {
                    .accordion__title::after {
                        content: "-";
                    }
                }

                .accordion__content {
                    h1, h2, h3, h4 {
                        padding-left: 0;
                    }

                    .table-container {
                        overflow: auto;

                        table {
                            border-collapse: collapse;
                        }
                    }
                }
            }
        }
    }
}