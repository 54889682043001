@use "components/variables" as variables;
@use "components/content-templates.scss"; // not LT
@use "components/content_page/content_page.scss";

.replace {
    &-answers {
        margin: 5px;
        background-color: #efefef;
        border-radius: 4px;
        color: variables.$primary;
        overflow: auto;
        border: solid 1px variables.$primary;
        width: 105px;
        display: flex;

        label {
            float: left;
            width: 4em;
            display: block;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            &:first-of-type {
                border-right: 1px solid variables.$primary;
            }

            span {
                text-align: center;
                padding: 7px;
                display: block;
            }

            input {
                position: absolute;
                top: -20px;
            }
        }

        input:checked + span {
            background-color: variables.$primary;
            color: variables.$white;
        }
    }

    &-answer {
        margin-right: 10px;
    }

    &-question {
        font-weight: bold;
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;
        color: variables.$primary;
    }

    &-product {
        &-name {
            font-size: variables.$font-size-xl;
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
        }

        &-image img {
            width: 300px;
            margin: 15px 30px 75px 15px;
        }
    }

    &-main-content {
        display: flex;
        flex-basis: 33%;
        flex-grow: 1;

        div {
            vertical-align: top;
        }

        & > div {
            margin: 5px;
        }
    }

    &-step {
        &-line {
            height: 15px;
        }

        &-number {
            color: variables.$secondary;
            font-weight: 600;
            font-size: variables.$font-size-7xl;
            float: left;
            margin-right: 20px;
        }

        &-description {
            color: variables.$primary;
            font-weight: 600;

            &-small {
                font-size: variables.$font-size-lg;
                font-weight: normal;

                p {
                    color: variables.$primary;
                }
            }
        }
    }

    &-summary-container {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }

    &-answers-summary {
        width: 50%;
        margin: 0 auto;
        text-align: left;
    }

    &-no-repurchase {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-answers-question {
        margin-bottom: 8px;
    }

    &-selected-answer {
        border: 1px solid #e0e0e0;
        text-align: center;
        border-radius: 8px;
        padding: 5px;
        margin: 5px 0px 16px 0px;
        width: 90px;
    }

    &-device-value {
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;
        font-size: variables.$font-size-default;
    }

    &-price {
        font-size: variables.$font-size-5xl;
        font-weight: variables.$font-weight-bold;
        color: variables.$supporting-01-dark;
        margin: 16px 0px;

        &-container {
            width: 100%;
        }
    }
}

.cookie-disclaimer {
    padding: 16px 0px;
}

.disabled-question {
    label {
        color: variables.$neutral-lighter;
    }

    .replace-answers,
    .replace-requirements {
        display: none;
    }
}

.progressbar {
    border-bottom:1px solid variables.$neutral-lighter-100;
    display: flex;
    justify-content: center;
    margin: unset;
    

    li {
        display: inline-block;
        position: relative;
        text-align: center;
        width: 22%;
        color: variables.$neutral-dark;
        
        .step-name {
            
            width: 50%;
        }

        &.current {
            color: variables.$primary;
            font-weight: bold;
        }

        &:first-child {

            &:before {
                content: "1";
            }

            &:after {
                display: none;
            }
        }

        &:last-child {
            &:before {
                content: "3";
            }
        }

        &:before {
            align-items: center;
            background-color: variables.$primary-lighter-10;
            border: 1px solid variables.$neutral-lighter;
            border-radius: 50%;
            color: variables.$neutral-lighter;
            content: "2";
            display: flex;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            height: 48px;
            justify-content: center;
            left: calc(50% - 48px / 2);
            line-height: 22px;
            position: relative;
            width: 48px;
            z-index: 1;
            margin-bottom: 6px;
        }

        &:after {
            background: variables.$neutral-lighter;
            //css hacks ftw - repeating linear gradient to create spacing between dashed
            background: repeating-linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,0) 4px, variables.$neutral-lighter 2px, variables.$neutral-lighter 8px);
            content: '';
            height: 1px;
            position: absolute;
            top: 24px;
            right: 50%;
            width: 100%;
            z-index: 0;
        }
    }

    .active {
        color: variables.$primary;
        
        &:before {
            border-color: variables.$primary;
        }
        
        &:after {
            background: repeating-linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,0) 4px, variables.$primary 2px, variables.$primary 8px);            
        }
        
        &:before, &:after {
            color: variables.$primary;
        }
    }
}

div#searchProductsContainer {
    text-align: center;
    font-size: variables.$font-size-lg;
}

.calc-content {
    text-align: left;

    h1 {
        text-align: center;

        > .replace-step-description {
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
        }
    }
    
    .autocomplete-list {
        z-index: 101;
        
        li {
            width: 498px;
        }
    }
}

.replace-product-search {
    display: block;
    text-align: left;
    width: 100%;

    .col-lg-12 {
        padding: unset;
    }

    label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .search-products {
        margin-top: 12px;
    }
}

.replace-question-container {
    margin-bottom: 20px;
    
    .replace-question {
        font-size: larger;
    }

    .replace-answers {
        margin-bottom: 20px;
    }

    .replace-requirements{
        ul>li {
            list-style: unset;
        }
        
        li,span{
            background: unset !important;
        }
    }
}



@media(max-width: variables.$responsive-break - 1) {
    #store-list-panel {
        overflow: scroll;
    }

    #staticContentContainer > br {
        display: none;
    }

    #staticContentContainer > .row {
        flex-direction: column;
        padding-left: 16px;
        padding-right: 16px;

        .col-lg-6 {
            width: 100%;
        }
    }

    .maps {
        display: none;
    }

    .col-lg-12 {
        width: 100%;
    }
}

.store-list-toggle--active .maps {
    display: block;
}

#staticContentContainer {
    .map-content {
        .small-header {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            padding: 16px 24px;
            color: variables.$primary;
        }

        .cta {
            padding: 16px 48px;
            background: variables.$neutral-lighter-25;
            border-top: 1px solid variables.$neutral-lighter-50;
            border-bottom: 1px solid variables.$neutral-lighter-50;
            
            p {
                color: variables.$primary;
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                padding: 0;
            }
        }

        .maps-closest__button {
            display: none;
        }

        .maps-list {
            padding: 0 48px;
        }
        
        @media(max-width: variables.$responsive-break - 1) {
            
            .cta {
                padding: 16px 28px;
            }
            
            .maps-list {
                padding: 0 28px;
            }            
        }    
    }

    @media(max-width: variables.$responsive-break - 1) {
        #stores-list {
            .panel-right {
                .responsive-panel {
                    padding: 0;
                }
            }
        }
    }    
    
    @media(min-width: variables.$large-responsive-break) {
        .lr-panel--active {
            .panel-right {
                left: 75%;
                width: 25%;
            }
        }
    }
    
    @media(max-width: variables.$responsive-break - 1) {
        font-size: variables.$font-size-default;

        h1 > span.replace-step-description {
            font-weight: variables.$font-weight-bolder;
            font-size: variables.$font-size-lg;
        }

        h1 > span.replace-step-description-small > p {
            color: variables.$primary;
            font-size: variables.$font-size-md;
        }

        p {
            color: variables.$black;
        }

        #calcContainer {
            margin-top: 0 !important; // static HTML inline style override
        }

        .other-services, .replace-summary-container {
            display: none;
        }

        .store-list-toggle > button {
            width: 100%;
        }

        .progressbar {
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                width: 100%;
                height: 86px;
                padding-right: 10px;
                padding-left: 68px;
                text-align: left;

                &:first-child {
                    &:before {
                        //left: 40%;
                    }

                    &:after {
                        display: none;
                    }
                }

                &:last-child {
                    &:before {
                        right: 40%;
                    }
                }

                &:before {
                    display: flex;
                    left: 0;
                    top: 0;
                    justify-content: center;
                }

                &:after {
                    z-index: 0;
                    left: 25px;
                    width: 1px;
                    height: 100%;
                    background:repeating-linear-gradient(0, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 0) 4px, #C0C3CE 2px, #C0C3CE 8px)
                }
            }
        }

        .replace-product-search {
            position: relative;
        }

        .replace-device-value {
            color: variables.$black;
            font-weight: variables.$font-weight-normal;
        }

        .autocomplete-list {
            z-index: variables.$zindex-maps-overlay + 1;
            
            li {
                width: inherit;
            }
        }

        .replace-product-form {
            display: grid;
            grid-template-columns: 1fr 4fr;
            grid-template-rows: 1fr 1fr auto auto;
            grid-template-areas:
                "image name" "image return" "questions questions" "continue continue";
            column-gap: 32px;

            .replace-product-search-container {
                grid-area: 1 / 1 / last-line / last-line;
                margin-bottom: 16px;
            }

            .replace-product-name {
                grid-area: name;
                text-align: left;
                line-height: 1.5;
            }

            .replace-product-image {
                grid-area: image;

                img {
                    width: 100%;
                    margin: 0;
                }
            }

            .button span.icon {
                display: none;
            }

            .replace-main-content {
                grid-area: questions;
                width: 100%;

                .replace-question-container {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    
                    .replace-requirements p {
                        text-align: left;
                    }
                    
                    .replace-question {
                        font-weight: variables.$font-weight-bold;
                        font-size: larger;
                        color: variables.$black;
                        margin-bottom: 16px;
                    }

                    .replace-answers {
                        order: 10;
                        justify-content: center;
                        width: 100%;
                        border-radius: 0;
                        background-color: #EBF3FF;
                        border: none;

                        label {
                            font-weight: variables.$font-weight-bold;
                            height: 60px;
                            width: 120px;
                            border: 1px solid variables.$primary;
                            margin: 15px;

                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .disabled-question .replace-question {
                    font-size: variables.$font-size-default;
                    color: variables.$neutral-lighter;
                }
            }

            .button-return {
                grid-area: return;
            }

            .button-continue {
                grid-area: continue;
            }
        }


        .col-lg-6.justify-content-center {
            order: -1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 48px;
        }

        .col-lg-6.justify-content-center img {
            width: 80% !important; // static HTML inline style override
            height: 80% !important; // static HTML inline style override
        }

        .col-lg-6.justify-content-center i {
            font-size: 100px !important; // static HTML inline style override
        }
    }

    @media(min-width: variables.$responsive-break) {
        .replace-product-form {
            display: grid;
            grid-template-columns: 1fr 4fr;
            grid-template-rows: 1fr auto 1fr;
            grid-template-areas:
                "name name" "image questions" "return continue";

            .replace-product-search-container {
                grid-area: 1 / 1 / last-line / last-line;
                margin: 0;
            }

            .replace-product-name {
                grid-area: name;
            }

            .replace-product-image {
                grid-area: image;
            }

            .replace-main-content {
                grid-area: questions;
            }

            .button-return {
                grid-area: return;
                justify-self: flex-start;
            }

            .button-continue {
                grid-area: continue;
                justify-self: flex-end;
            }
        }

        .store-list-toggle > button {
            display: none;
        }
    }
}


